
.topbar {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.topbar-title {
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
  color: var(--secondary-color);
}

.topbar-buttons {
  display: flex;
  gap: 10px;
}

.topbar-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 5px 15px;
  border-radius: 50px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.topbar-button:hover {
  transform: scale(1.05);
}

.main-content {
  padding-top: 50px; /* Adjust based on your topbar height */
}

.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
}

button {
  font-family: 'Bangers', cursive;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}
