@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');


:root {
    --primary-color: #FFD700;
    /* Yellow */
    --secondary-color: #8B4513;
    /* Brown */
    --accent-color: #FF6347;
    /* Tomato */
    --text-color: #333333;
    --background-color: #F5F5F5;
}

body {
    margin: 0;
    font-family: 'Fredoka One', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    color: var(--text-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}